import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import Form from "react-bootstrap/Form";
import NotificationSchema from "./validations/notificationSchema";

import { format } from "date-fns";

import Sallie from "../Sallie";
import OnboardingStepHeader from "./OnboardingStepHeader";
import SubmitButton from "../StyledComponents/SubmitButton";
import TimeInput from "../TimeInput";

import { postTrackingPrefs } from "../../api/TBIRequests";

import stringifyDayOfWeek from "../../lib/stringifyDayOfWeek";
import customizeMessages from "../../lib/customizeMessages";

const NotificationPrefs = ({ user, setOnboardingPercent }) => {
  const [submitting, setSubmitting] = useState(false);
  const [isEmailAlert, setIsEmailAlert] = useState(true);
  const [timezone, setTimezone] = useState("America/New_York");
  const history = useHistory();
  useEffect(() => {
    setOnboardingPercent(80);
  }, []);

  useEffect(() => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (timezone) {
      setTimezone(timezone);
    } else {
      //Intl object doesn't support IE brower, so it returns undefined.
      setTimezone("America/New_York");
    }
  }, []);

  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      emailAlert: true,
      hour: "04",
      partOfDay: "PM",
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
    },
    resolver: yupResolver(NotificationSchema),
  });

  const disableDayOfWeek = (e) => {
    if (e.target.checked) {
      setIsEmailAlert(true);
    } else {
      setIsEmailAlert(false);
    }
  };

  const onSubmit = (data) => {
    setSubmitting(true);
    let notificationData = { emailAlert: data.emailAlert };
    const time = format(
      new Date(
        0,
        0,
        0,
        data.partOfDay === "AM" ? data.hour : data.hour + 12,
        0),
      "HH:mm");

    notificationData.timeAlert = time;

    const dayOfWeek = stringifyDayOfWeek(data);
    notificationData.dayOfWeek = dayOfWeek;
    notificationData.tz = timezone;

    postTrackingPrefs({ data: notificationData })
      .then(() => history.push("/Verification/EmailSent"))
      .catch(() => history.push("/oops"));
  };

  const getPossibleMessages = () => ({
    mainHeader: {
      Stroke: "Step 7: Notification Preferences",
      tbiPatient: "Step 7: Notification Preferences",
      caregiver: "Step 7: Notification Preferences"
    },
    sallieText: {
      Stroke: "Would you like to receive reminders to track your symptoms?",
      tbiPatient: "Would you like to receive reminders to track your symptoms?",
      caregiver: "Would you like to receive reminders to track the patient's symptoms?"
    },
  });

  const getMessage = customizeMessages({ getPossibleMessages, user })

  return (
    <>
      <OnboardingStepHeader text={getMessage("mainHeader")} />
      <Sallie text={getMessage("sallieText")} />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Label
          as="h6"
          style={{ width: "100%", margin: "10px 0px 20px 0px" }}
        >
          Please select how you would like to recieve tracking reminders
        </Form.Label>
        <Form.Row>
          <Form.Group controlId="email">
            <Form.Check
              name="emailAlert"
              ref={register}
              defaultValues={true}
              required
              type="checkbox"
              label="Email"
              onChange={disableDayOfWeek}
              style={{ display: "inline-block" }}
            />
            <span className="required">*</span>
          </Form.Group>
        </Form.Row>

        <TimeInput
          errors={errors}
          register={register}
          label="Set your reminder time"
          disableStatus={!isEmailAlert}
        />

        <SubmitButton disabled={submitting} type="submit">
          Save & Continue &gt;
        </SubmitButton>
      </Form>
    </>
  );
};

export default NotificationPrefs;
