import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import OnboardingStepHeader from "./OnboardingStepHeader";
import Sallie from "../Sallie";
import SubmitButton from "../StyledComponents/SubmitButton";

const EmailSent = ({ setOnboardingPercent, user }) => {
  const [submitting, setSubmitting] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setOnboardingPercent(90);
  }, []);

  const onSubmit = () => {
    setSubmitting(true);
    history.push("/");
  };


  let SallieMsg = `We have sent an email to ${user.email} to make sure you own it. Please check your inbox to verify your email address within 24 hours!`;
  return (
    <>
      <OnboardingStepHeader text="Step 7-b: Email Confirmation" />
      <Sallie text={SallieMsg} />
      <SubmitButton
        disabled={submitting}
        onClick={onSubmit}
      >
        Log In
      </SubmitButton>
    </>
  );
};

export default EmailSent;
