import React, { useState } from "react"
import Form from "react-bootstrap/Form"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers"
import { Row, Col } from "react-bootstrap/"
import { useHistory } from "react-router-dom"
import LabelledInput from "./LabelledInput"
import helpSchema from "./helpSchema"
import SubmitButton from "./StyledComponents/SubmitButton"
import Sallie from "./Sallie"
import LinkButton from "./StyledComponents/LinkButton"
import { postHelp } from "../api/helpReq"

const HelpPage = () => {
  const history = useHistory()
  const [submitted, setSubmitted] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [err, setErr] = useState(false)

  const { errors, handleSubmit, register, reset } = useForm({
    resolver: yupResolver(helpSchema)
  })
  const onSubmit = data => {
    setSubmitting(true)
    data.email = data.email.toLowerCase();
    postHelp(data)
      .then(() => {
        setSubmitted(true)
        reset()
        setErr(false)
      })
      .catch(() => setErr(true))
      .finally(() => setSubmitting(false))
  }

  let SallieText
  if (err) {
    SallieText =
      "We weren't able to process your request. If you would still like to request assistance you may email help@powerofpatients.com."
  } else if (submitted) {
    SallieText = (
      <div>
        Successfully submitted your ticket! Please give up to two business days
        for a reply. <LinkButton onClick={history.goBack}>Go Back</LinkButton>
      </div>
    )
  } else {
    SallieText =
      "Please enter contact info and a description of the problem you're having below OR Tell us what you would like added."
  }

  return (
    <Row className="d-flex justify-content-center">
      <Col xs={10} md={8} lg={6}>
        <Sallie text={SallieText} />
        <Form className="mt-2" onSubmit={handleSubmit(onSubmit)}>
          <LabelledInput
            name="email"
            labelAs="h6"
            errors={errors}
            inputRef={register}
            required
            autoFocus
          />
          <LabelledInput
            name="name"
            labelAs="h6"
            errors={errors}
            inputRef={register}
            required
          />

          <LabelledInput
            labelAs="h6"
            inputAs="textarea"
            name="ticket"
            label="What do you need help with?"
            extraInfo="Please include as much information as possible, including your Operating System and Browser"
            errors={errors}
            inputRef={register}
            required
          />
          <div className="d-flex justify-content-between">
            <SubmitButton onClick={history.goBack} type="button">
              Go Back
            </SubmitButton>
            <SubmitButton disabled={submitting} type="submit">
              Submit
            </SubmitButton>
          </div>
        </Form>
      </Col>
    </Row>
  )
}

export default HelpPage
