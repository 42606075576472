import React, { useState, useEffect, useContext } from "react";
import { PatientContext } from "../../lib/main-context";
// Hooks
import { useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
// Bootstrap
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
// Validation Rules
import SignupSchema from "./validations/registerValidation";
import { registerUser, registerPatient } from "../../api/auth";
import { getProviderTokenInformation, sendConfirmEmail, checkExistingList } from "../../api/helpReq";
import {
  createGoals,
  getPatientTypes, getProviderTypes,
  getReferralGroups,
} from "../../api/TBIRequests";
// Components
import Sallie from "../Sallie";
import PrivacyPage from "../PrivacyPage";
import LinkButton from "../StyledComponents/LinkButton";
import LabelledInput from "../LabelledInput";
import SubmitButton from "../StyledComponents/SubmitButton";
import OnboardingStepHeader from "./OnboardingStepHeader";
import CountryList from "../../api/countryList.json";
import Dropdown from 'react-multilevel-dropdown';


const BasicInfo = ({ setOnboardingPercent, setUser, setPatient, setPatientList }) => {
  const location = useLocation();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [viewPolicy, setViewPolicy] = useState(false);
  const [otherType, setOtherType] = useState(false);
  const [patientTypes, setPatientTypes] = useState(null);
  const [referralGroups, setreferralGroups] = useState(null);
  const [referralValue, setReferralValue] = useState("Life University");
  const [selectReferral, setSelectReferral] = useState("Life University");
  const [userValue, setUserValue] = useState("tbiPatient");
  const [providerEmail, setProviderEmail] = useState(null);
  const [providerTypes, setProviderTypes] = useState(null);
  // If we choose to implement otherProviderTypeValue
  // const [providerTypeValue, setProviderTypeValue] = useState(null);

  const url = new URLSearchParams(location.search);
  const isProviderForm = Boolean(url.get("token"));

  const { register, handleSubmit, errors, setError, setValue } = useForm({
    resolver: yupResolver(SignupSchema({ isProviderForm })),
  });

  useEffect(() => {
    setOnboardingPercent(10);
    if (location.search.length > 0) {
      setValue("email", url.get("email"));
      setValue("firstName", url.get("first"));
      setValue("lastName", url.get("last"));
      setReferralValue(url.get("referral"));
      const token = url.get("token");
      if (token) {
        const data = { token };
        getProviderTokenInformation({ data })
          .then((res) => {
            const { data } = res;
            // setValue("email", data.providerEmail);
            // Assuming we are hiding the email field for providers in the JSX
            setProviderEmail(data.providerEmail);
          })
          .catch((err) => {
            console.error(err);
            history.push("/oops");
          })
      }
    }
  }, []);

  const menu =
  {
    name: "Referral",
    // children: [
    //   {
    //     name: "Brain Injury Group",
    //     children: [
    //       "BIA-GA", "BIA-IN", "BIA-MD", "BIA-NCs", "BIA-VT", "In-Between-The-Ears", "ND-BIN", "Team Luke", "Warriors 2 Citizens"
    //     ]
    //   },
    //   {
    //     name: "Provider",
    //     children: [
    //       "Physiatrist",
    //       "Neurologist",
    //       "Pediatrician",
    //       "Neuro-Optometrist/Optometrist",
    //       "Ophthalmologist",
    //       "Audiologist",
    //       "Vestibular",
    //       "Speech Language Therapist",
    //       "Occupational Therapist",
    //       "Physical Therapist",
    //       "Orthopedist",
    //       "Athletic Trainer",
    //       "Psychologist",
    //       "Cognitive Behavior Therapist",
    //       "Case Manager",
    //       "General Practitioner",
    //       "Physician Assistant",
    //       "Nurse Practitioner",
    //       "Chiropractor",
    //     ]
    //   },
    //   {
    //     name: "Social Media",
    //     children: [
    //       "FaceBook",
    //       "Instagram",
    //       "LinkedIn",
    //       "Twitter",
    //       "Blog",
    //     ]
    //   },
    //   {
    //     name: "Organization",
    //     children: [
    //       "BrainLine",
    //       "Your Movement Specialist",
    //       "Minds Matter, LLC",
    //       "Healthy Truckers of America",
    //       "Cognivue",
    //       "Unite-Us"
    //     ]
    //   },
    //   {
    //     name: "Clinic",
    //     children: [
    //       "Atlas Concussion",
    //       "Vivolor",
    //       "Your Movement Specialists",
    //     ]
    //   }

    // ]
  };


  useEffect(() => {
    basicInfoFormFill();
  }, []);

  const basicInfoFormFill = async () => {
    try {
      if (!isProviderForm) {
        getPatientTypes().then((res) => {
          const { data } = res;
          setPatientTypes(data);
        });
      } else {
        getProviderTypes().then((res) => {
          const { data } = res;
          setProviderTypes(data);
        });
      }

      getReferralGroups().then((res) => {
        const { data } = res;
        setreferralGroups(data);
      });
    } catch {
      history.push("/oops");
    }
  };

  useEffect(() => {
    setValue("userType", "provider");
  }, [patientTypes]);

  // Thinking about allowing user to view password
  // const [showPassword, setShowPassword] = useState(false)

  const onSubmit = async (data) => {
    if (otherType) {
      data.userType = data.otherType;
      delete data.otherType;
    }
    if (providerEmail) {
      data.email = providerEmail;
    }
    if (isProviderForm) {
      const url = new URLSearchParams(location.search);
      const token = url.get("token");
      if (token.substring(0, 9) == "caregiver") {
        data.referral = "caregiver";
      } else {
        data.referral = "patient";
      }
      data.userType = "provider";
    }
    try {
      setIsLoading(true);
      const url = new URLSearchParams(location.search);
      const token = url.get("token");
      if (data.userType === "provider" || token) {
        data.providerToken = token;
      }
      if (selectReferral) {
        data.referral = selectReferral;
      }

      data.email = data.email.toLowerCase();
      
      const registerUserResponse = await registerUser(data);
      const responseUserData = registerUserResponse.data;
      setUser(responseUserData);
      const { firstName, lastName, email, userType } = responseUserData;
      await sendConfirmEmail({
        email: email,
        name: firstName + " " + lastName,
      });
      await checkExistingList({
        email: email,
      })
      if (userType === "caregiver") {
        history.push("/RegisterPatient", {
          fromPatientControl: false,
        });
      } else if (userType === "Stroke") {
        data.relationshipType = "self";
        data.status = userType;
        const registerPatientResponse = await registerPatient(data);
        const responsePatientData = registerPatientResponse.data;
        setPatient(responsePatientData);
        await createGoals({ patientId: responsePatientData.patientId });
        history.push("/RecentStroke1", {
          fromPatientControl: false,
        });
      }
      else if (userType !== "provider" && userType !== "Stroke") {
        data.relationshipType = "self";
        data.status = userType;
        const registerPatientResponse = await registerPatient(data);
        const responsePatientData = registerPatientResponse.data;
        // const { patientId } = responsePatientData[0];
        // // See note in App.js for setPatientList && updatePatient
        // createGoals({ patientId });
        // setPatientList(responsePatientData);
        // updatePatient(responsePatientData, patientId);
        setPatient(responsePatientData);
        await createGoals({ patientId: responsePatientData.patientId });
        // using patientList doesn't make sense with a patient tracking themselves
        // const getPatientListResponse = await getPatientList();
        // setPatientList(getPatientListResponse.data);
        history.push("/RecentTBI1", {
          fromPatientControl: false,
        });
      } else {
        history.push("/");
      }
    } catch (err) {
      console.log(err);
      if (err.message && err.message.includes("409")) {
        setError("email", {
          type: "emailTaken",
          message: "Email is already taken!",
        });
      } else if (err.message && err.message.includes("406")) {
        setError("email", {
          type: "emailNotValid",
          message: "This is not valid email.",
        });
      } else {
        history.push("/oops");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const onSelectUserType = (e) => {
    setUserValue(e.target.value);
    if (e.target.value === "otherType") {
      setOtherType(true);
    } else {
      setOtherType(false);
    }
  };

  let SallieMsg = "Lets start with some basic information!";

  if (
    errors.dob &&
    errors.dob.message &&
    errors.dob.message === "You must be 13 or older"
  ) {
    SallieMsg = "You need your parents/caregiver's help to create account";
  }

  // const togglePassword = () => {
  //   setShowPassword(!showPassword)
  // }

  return (
    <>
      <div style={{ display: viewPolicy ? "block" : "none" }}>
        <PrivacyPage onClick={() => setViewPolicy(false)} />
      </div>
      <div style={{ display: viewPolicy ? "none" : "block" }}>
        <OnboardingStepHeader text="Step 1-a: Account Information" />
        <Sallie text={SallieMsg} />
        <Form
          autoComplete="on"
          action="/post"
          method="POST"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Form.Row>
            <Col md={8}>
              {!isProviderForm ? (
                <Form.Group controlId="userType">
                  <Form.Label>
                    Registration Type <span className="required">*</span>
                  </Form.Label>
                  <Form.Control
                    autoFocus={true}
                    name="userType"
                    as="select"
                    ref={register}
                    isInvalid={errors.userType}
                    onChange={onSelectUserType}
                    value={userValue}
                  // defaultValue="US"
                  >
                    <option
                      key={"Chiropractic Patient"}
                      value={"Chiropractic Patient"}
                    >
                      {"Chiropractic Patient"}
                    </option>

                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.userType && errors.userType.message}
                  </Form.Control.Feedback>
                </Form.Group>
              ) : (
                  <Form.Group controlId="providerType">
                    <Form.Label>
                      Provider Type <span className="required">*</span>
                    </Form.Label>
                    <Form.Control
                      autoFocus={true}
                      name="providerType"
                      as="select"
                      ref={register}
                      isInvalid={errors.providerType}
                    // If we choose to implement otherProviderType
                    // onChange={onSelectProviderType}
                    // value={providerTypeValue}
                    >
                      {providerTypes
                        ? providerTypes.map((providerType) => (
                          <option
                            key={providerType.constantString}
                            value={providerType.constantString}
                          >
                            {
                              providerType.displayString.charAt(0).toUpperCase() + providerType.displayString.slice(1)}
                          </option>
                        ))
                        : null}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.providerType && errors.providerType.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                )}
            </Col>

            <Col md={4}>
              <Form.Group controlId="gender">
                <Form.Label>
                  Gender<span className="required">*</span>
                </Form.Label>
                <Form.Control
                  name="gender"
                  as="select"
                  ref={register}
                  isInvalid={errors.gender}
                >
                  <option value=""></option>
                  <option value="female">Female</option>
                  <option value="male">Male</option>
                  <option value="other">Other</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.gender && errors.gender.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>


            {otherType && (
              <Col md={8}>
                <LabelledInput
                  name="otherType"
                  required
                  label="Other Registration Type"
                  inputRef={register}
                  errors={errors}
                />
              </Col>
            )}

            <Col md={3}>
              <LabelledInput
                name="firstName"
                required
                label="First Name"
                autoComplete="given-name"
                inputRef={register}
                errors={errors}
              />
            </Col>

            <Col md={3}>
              <LabelledInput
                name="lastName"
                autoComplete="family-name"
                label="Last Name"
                required
                inputRef={register}
                errors={errors}
              />
            </Col>
            <Col md={6}>
              <Form.Group controlId="race">
                <Form.Label>
                  Race<span className="required">*</span>
                </Form.Label>
                <Form.Control
                  name="race"
                  as="select"
                  ref={register}
                  isInvalid={errors.race}
                >
                  <option value=""></option>
                  <option value="American Indian or Alaskan Native">American Indian or Alaskan Native</option>
                  <option value="Asian Indian">Asian Indian</option>
                  <option value="Black or African American">Black or African American</option>
                  <option value="Chamorro or Guamanian">Chamorro or Guamanian </option>
                  <option value="Chinese">Chinese </option>
                  <option value="Filipino">Filipino</option>
                  <option value="Japanese">Japanese</option>
                  <option value="Korean">Korean</option>
                  <option value="White">White</option>
                  <option value="otherRace">Other Race</option>
                  <option value="Prefer not to answer ">Prefer not to answer </option>
                  {/* <option value=""></option> */}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.race && errors.race.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>


            <Col md={6}>
              <Form.Group controlId="ethnicity">
                <Form.Label>
                  Ethnicity<span className="required">*</span>
                </Form.Label>
                <Form.Control
                  name="ethnicity"
                  as="select"
                  ref={register}
                  isInvalid={errors.ethnicity}
                >
                  <option value=""></option>
                  <option value="Yes">Yes, Hispanic, Latino/a/x or Spanish origin </option>
                  <option value="No">No, Not of Hispanic origin </option>
                  <option value="otherEthnicities">Some other ethnicities or origin </option>
                  <option value="notToAnswer">Prefer not to answer </option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.ethnicity && errors.ethnicity.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group controlId="veteran">
                <Form.Label>
                  Veteran's Status<span className="required">*</span>
                </Form.Label>
                <Form.Control
                  name="veteran"
                  as="select"
                  ref={register}
                  isInvalid={errors.veteran}
                >
                  <option value=""></option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                  <option value="notToAnswer">Prefer not to answer</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.veteran && errors.veteran.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col md={6}>
              <LabelledInput
                name="phoneNumber"
                type="tel"
                label="Mobile Phone Number"
                autoComplete="tel"
                inputRef={register}
                errors={errors}
              />
            </Col>
            {!isProviderForm &&
              <Col md={6}>
                <LabelledInput
                  name="email"
                  type="email"
                  label="Email"
                  autoComplete="username"
                  required
                  inputRef={register}
                  errors={errors}
                />
              </Col>
            }

            <Col md={6}>
              <LabelledInput
                name="dob"
                type="DOB"
                label="Date of Birth (MM/DD/YYYY)"
                required
                inputRef={register}
                errors={errors}
              />
            </Col>

            {!isProviderForm &&
              <Col md={6}>
                <Row className="d-flex">
                  {/* <Col md={4} className="d-flex"> */}
                  <Dropdown
                    position="right"
                    title={menu.name}
                    menuClassName="text-14 py-8 px-0 my-0 mx-16 border-b-1 border-solid border-blue hover:border-black"
                    style={{
                      // width: "9rem",
                      marginLeft: "1.3rem",
                      fontWeight: "normal",
                      fontSize: "15px",
                      border: "1px solid #d4d4d4"
                    }}>
                    {/* {menu.children &&
                      menu.children.map((category) => (
                        <Dropdown.Item
                          style={{
                            // marginTop: "-0.4rem",
                          }}>
                          {category.name}
                          <Dropdown.Submenu position="right"
                            style={{
                              marginLeft: "0.3rem",
                              marginTop: "-0.4rem",
                              width: "15rem",
                              height: "25rem",
                              overflow: 'scroll',
                              fontWeight: "normal",
                              fontSize: "15px"
                            }}>
                            {category.children &&
                              category.children.map((select) => (
                                <Dropdown.Item onClick={() => setSelectReferral(select)}>{select}</Dropdown.Item>
                              ))}
                          </Dropdown.Submenu>

                        </Dropdown.Item>
                      ))} */}
                  </Dropdown>
                  {/* <span className="required">*</span> */}
                  {/* </Col> */}

                  {/* <Col md={7}> */}
                  <div
                    style={{
                      // width: "9rem",
                      margin: "0 1rem 1rem 0.5rem",
                      minHeight: "2.3rem",
                      minWidth: "11rem",
                      lineHeight: "2.3rem",
                      fontWeight: "normal",
                      fontSize: "15px",
                      border: "1px solid #d4d4d4",
                      borderRadius: "3px",
                      backgroundColor: "white",
                      alignItems: "center",
                      textAlign: "center",
                      justifyContent: "center"
                    }}>
                    {selectReferral}</div>
                </Row>
              </Col>
            }


            {/* {!isProviderForm &&
              <Col md={6}>
                <Form.Group controlId="referral">
                  <Form.Label>Referral</Form.Label>
                  <Form.Control
                    name="referral"
                    as="select"
                    ref={register}
                    onChange={(e) => setReferralValue(e.target.value)}
                    value={referralValue}
                  >
                    {referralGroups
                      ? referralGroups.map((group) => (
                        <option key={group.group_name} value={group.group_name}>
                          {group.group_name}
                        </option>
                      ))
                      : null}
                  </Form.Control>
                </Form.Group>
              </Col>
            } */}

            <Col md={6}>
              <Form.Group controlId="country">
                <Form.Label>Country</Form.Label>
                <Form.Control
                  name="country"
                  as="select"
                  ref={register}
                  defaultValue="US"
                >
                  {Object.keys(CountryList)
                    .sort()
                    .map((countryName) => (
                      <option
                        key={CountryList[countryName]}
                        value={CountryList[countryName]}
                      >
                        {countryName}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            </Col>

            <Col md={6}>
              <LabelledInput
                name="postalCode"
                autoComplete="postal-code"
                label="Zipcode"
                required
                inputRef={register}
                type="text"
                errors={errors}
              />
            </Col>

            <Col md={6}>
              <LabelledInput
                name="password"
                type="password"
                label="Password"
                required
                autoComplete="new-password"
                inputRef={register}
                errors={errors}
              />
            </Col>
            <Col md={6}>
              <LabelledInput
                type="password"
                name="passwordConfirmation"
                label="Confirm Password"
                autoComplete="new-password"
                required
                inputRef={register}
                errors={errors}
              />
            </Col>

            {/* <Form.Row>
            <Form.Check
              name="showPassword"
              type="checkbox"
              label="Show Password"
              onClick={togglePassword}
            />
          </Form.Row> */}
            <Col md={6}>
              <Form.Check
                name="agreedToTerms"
                ref={register}
                type="checkbox"
                id="agreedToTerms"
                label={
                  <div>
                    I agree to the{" "}
                    <LinkButton
                      type="button"
                      onClick={() => setViewPolicy(true)}
                    >
                      terms and conditions
                    </LinkButton>
                  </div>
                }
                isInvalid={errors.agreedToTerms}
              />

              {errors.agreedToTerms && (
                <Form.Control.Feedback
                  style={{ display: "block" }}
                  type="invalid"
                >
                  {errors.agreedToTerms.message}
                </Form.Control.Feedback>
              )}
            </Col>

            <Col md={6}>
              <SubmitButton disabled={isLoading} type="submit">
                Confirm
              </SubmitButton>
            </Col>
          </Form.Row>
        </Form>
      </div>
    </>
  );
};

export default BasicInfo;
