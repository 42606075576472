import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers"

import { Row, Col, Form } from "react-bootstrap"
import Sallie from "../Sallie"
import LabelledInput from "../LabelledInput"
import DisabledButton from "../StyledComponents/DisabledButton"
import { ResetPw1Schema } from "./validation"
import { sendResetPwEmail } from "../../api/helpReq"

const ResetPassword1 = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [sallieMsg, setSallieMsg] = useState(
    "Let me help you reset your password! Please type in your email address you used when you register."
  )
  const { register, handleSubmit, errors, formState } = useForm({
    resolver: yupResolver(ResetPw1Schema)
  })
  const { touched } = formState

  const onSubmit = async (data) => {
    setIsLoading(true);

    try {
      data.email = data.email.toLowerCase();
      await sendResetPwEmail(data);
      setSallieMsg(
        `If you are registered with this email. An Email with instructions on how to reset your password has been sent to ${data.email}.`
      );
    } catch (error) {
      console.error("Error sending password reset email:", error);

      if (error.response && error.response.status === 404) {
        // Handle 404 Not Found error
        setSallieMsg('No account with the provided email. Please check your email and try again.');
      } else {
        // Handle other errors (e.g., 500 Internal Server Error)
        setSallieMsg('An error occurred while sending the password reset email. Please try again later.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Sallie text={sallieMsg} />
      <Row className="d-flex justify-content-center">
        <Col xs={10} md={6}>
          <Form
            autoComplete="on"
            method="POST"
            className="mt-2"
            style={{ margin: "0 auto" }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <LabelledInput
              labelAs="h6"
              name="email"
              type="email"
              label="Email"
              autoComplete="email"
              inputRef={register}
              errors={errors}
              touched={touched}
            />
            <DisabledButton
              disabled={isLoading}
              className="w-100"
              type="submit"
              variant="outline-primary"
            >
              Continue
            </DisabledButton>
          </Form>
        </Col>
      </Row>
    </>
  )
}

export default ResetPassword1
